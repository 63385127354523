.about-head{
    color: #ffffff;
    position: relative;
    height: 215px;
    z-index: 100;

}
.about-head-child{
    position: absolute;
    top: 0;
    z-index: -2;
    width: 100%;
}

.about-child-2{
    content: "";
    background-color: rgb(1, 161, 209,0.5);
    position: absolute;
    top: 0;
    z-index:-1 ;
    width: 100%;
    height: 215px;
}

.about-head-img{
    width: 100%;
    height: 215px;
    object-fit: cover;
    object-position: 90% 20%;
}

.about-head h3{
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
    padding: 80px 10px;
    font-family: 'Averia-Bold';
}

.about-content{
    width: 80%;
    margin: auto;
    padding: 50px 0px;
}

.about-content .y-smile{
    font-family: 'Averia-Bold';
    color: #01A1D1;
    text-align: center;
}

.about-content .y-content{
    font-family: 'Averia-light';
    color: #5C5C5C;
    font-weight: 500;
    line-height: 25px;
}

.y-smile-contact{
    font-family: 'Averia-Bold';
    color: #01A1D1;
}

.my-team-head{
    padding: 50px 0px;
}
.my-team-head h3{
    font-family: 'Averia-Bold';
    color: #01A1D1;
    text-align: center;
}

.my-team-doc{
   width: 300px;
   height: 400px;
   border-radius: 20px;
   object-fit: cover;
   border: 1px solid #01A1D1;
}

.my-team-content{
    text-align: center;
    font-family: 'Averia-light';
    color: #5C5C5C;
    font-weight: 500;
}

.dr-name{
    padding: 20px auto;
    text-align: center;
    font-family: 'Averia-Bold';
    color: #01A1D1;
    font-weight: 500;
}

.dr-content{
    font-family: 'Averia-light';
    color: #5C5C5C;
    width: 80%;
    margin: auto;
}

.dr-treat-head{
    margin-top: 90px;
    margin-bottom: 20px;
}

.dr-treat-head h3{
    font-family: 'Averia-Bold';
    color: #01A1D1;
    text-align: center;
}

.dr-treat-content{
    width: 80%;
    margin: auto;
    text-align: center;
    font-family: 'Averia-Light';
    color: #5C5C5C;
}

.dr-treat-quote{
    font-family: 'Averia-Bold';
    color: #01A1D1;
}


.content-left{
    align-items: center;
    justify-content: center;
}
.content-right{
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

/* Small Mobile Styles */
@media (max-width: 480px) {
    .about-head {
        color: #fcfcfc;
        height: 150px;
        z-index: 100;
    }

    .about-head-child{
        position: absolute;
        top: 0;
        z-index: -2;
        width: 100%;
    }
    
    .about-child-2{
        content: "";
        background-color: rgb(1, 161, 209,0.5);
        position: absolute;
        top: 0;
        z-index:-1 ;
        width: 100%;
        height: 150px;
    }
    
    .about-head-img{
        width: 100%;
        height: 150px;
        object-fit: cover;
        object-position: 90% 20%;
    }

    .about-head h3 {
        font-size: 24px;
        padding: 30px 5px;
    }

    .about-content {
        width: 95%;
        padding: 20px 0;
    }

    .about-content .y-smile {
        font-size: 20px;
    }

    .about-content .y-content {
        font-size: 14px;
        line-height: 20px;
    }

    .my-team-doc {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .dr-name {
        padding: 10px 0;
        font-size: 18px;
    }

    .dr-content {
        width: 95%;
    }

    .dr-treat-content {
        width: 95%;
    }
}



/* Mobile Styles */
@media (max-width: 767px) {
    .about-head {
        background-color: rgba(152, 152, 152, 0.5);
        color: #ffffff;
    }

    .about-head h3 {
        font-size: 28px;
        padding: 40px 10px;
    }

    .about-content {
        width: 90%;
        padding: 30px 0;
    }

    .about-content .y-smile {
        font-size: 24px;
    }

    .about-content .y-content {
        font-size: 16px;
        line-height: 22px;
    }

    .my-team-doc {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    .dr-name {
        padding: 10px 0;
    }

    .dr-content {
        width: 90%;
    }

    .dr-treat-content {
        width: 90%;
    }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1024px) {
    .about-head h3 {
        font-size: 32px;
        padding: 60px 10px;
    }

    .about-content {
        width: 85%;
        padding: 40px 0;
    }

    .about-content .y-smile {
        font-size: 26px;
    }

    .about-content .y-content {
        font-size: 18px;
        line-height: 24px;
    }

    .my-team-doc {
        width: 250px;
        height: 350px;
    }
}

/* Laptop Styles */
@media (min-width: 1025px) {
    .about-head h3 {
        font-size: 40px;
        padding: 80px 10px;
    }

    .about-content {
        width: 80%;
        padding: 50px 0;
    }

    .about-content .y-smile {
        font-size: 28px;
    }

    .about-content .y-content {
        font-size: 20px;
        line-height: 25px;
    }

    .my-team-doc {
        width: 300px;
        height: 400px;
    }
}

