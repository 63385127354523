.home-left-content{
    margin: 20px 10px;
    color: #5c5c5c;
    font-family: 'Averia-Bold';
}

.home-content-bg{
    background-color: rgb(1, 160, 208,0.2);
    height: 600px;
}

.dental-font{
    color: #01A1D1;
}

.home-wel-head{
    font-size: 35px;
}

.home-wel-main-head{
    font-size: 50px;
}
.home-wel-content{
    font-size: 18px;
    font-family: 'Averia-Light';
}

.home-right-content{
    margin: 20px 10px;
    content: "";
}

.home-img-content{
  content:'';
  height: 500px;
}

.home-dental-pic{
    width: 100%;
    height: 550px;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    object-fit: cover;
    object-position: 10% 100%;
}

.apply-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Averia-light';
    background-color: #01A1D1;
    color: white;
    background: linear-gradient(to right, #01A1D1 50%, #03bdf6 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: background-position 0.3s, color 0.3s;
  }
  
  .apply-button:hover {
    background-position: right bottom;
  color: #ffffff;
  }

  .home-arrow{
    width: 25px;
    height: 25px;
  }

  .bg-y-neo{
    height: 700px;
    width: 80%;
    margin: 20px auto;
  }

  .neo-y-head{
    font-family: 'Averia-Bold';
    font-size: 40px;
    padding: 20px 0px;
  }

  .neo-y-left{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .neo-card{
    border-right: 1px solid #01A1D1;
    border-bottom: 1px solid #01A1D1;
    height: max-content;
    transition: all ease-in-out;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }

  .zig-zag{
    margin-top: 40px;
  }

  .neo-card-title{
    font-family: 'Averia-Bold';
    color: #01A1D1;
  }

  .neo-card-text{
    font-family: 'Averia-Light';
  }

  /* Tablet styles */
@media (max-width: 992px) {
  .home-content-bg {
      height: max-content;
  }

  .home-wel-head {
      font-size: 30px;
  }

  .home-wel-main-head {
      font-size: 40px;
  }

  .home-wel-content {
      font-size: 16px;
  }

  .home-dental-pic {
      height: 450px;
  }

  .bg-y-neo {
      height: max-content;
      width: 90%;
  }

  .neo-y-head {
      font-size: 35px;
  }

  .neo-card {
      height: max-content;
  }

  .home-dental-pic{
    width: 70%;
    height: 350px;
    object-fit: cover;
    object-position: 10% 100%;
    margin: auto;
}
  
}

/* Mobile styles */
@media (max-width: 768px) {
  .home-content-bg {
      height: max-content;
  }

  .home-wel-head {
      font-size: 25px;
  }

  .home-wel-main-head {
      font-size: 35px;
  }

  .home-wel-content {
      font-size: 14px;
  }

  .home-dental-pic {
      height: 300px;
      border-radius: 20px;
      object-position: center;
  }

  .apply-button {
      padding: 8px 16px;
      font-size: 14px;
  }

  .bg-y-neo {
      height: max-content!important;
      width: 100%;
  }

  .neo-y-head {
      font-size: 30px;
  }

  .neo-card {
      height: max-content;
  }
}

/* Small mobile styles */
@media (max-width: 576px) {
  .home-content-bg {
      height: max-content!important;
  }

  .home-wel-head {
      font-size: 20px;
  }

  .home-wel-main-head {
      font-size: 28px;
  }

  .home-wel-content {
      font-size: 12px;
  }

  .home-dental-pic {
      height: 250px;
  }

  .apply-button {
      padding: 6px 12px;
      font-size: 12px;
  }

  .bg-y-neo {
      height: max-content!important;
      width: 100%;
  }

  .neo-y-head {
      font-size: 25px;
  }

  .neo-card {
      height: max-content;
  }
  .zig-zag{
    margin-top: 10px;
  }
}


