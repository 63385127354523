@font-face {
  font-family: "Averia-Bold";
  src: url(../src/Components/Fonts/AveriaSerifLibre-Bold.ttf);
}

@font-face {
  font-family: "Averia-BoldItalic";
  src: url(../src/Components/Fonts/AveriaSerifLibre-BoldItalic.ttf);
}

@font-face {
  font-family: "Averia-italic";
  src: url(../src/Components/Fonts/AveriaSerifLibre-Italic.ttf);
}

@font-face {
  font-family: "Averia-Light";
  src: url(../src/Components/Fonts/AveriaSerifLibre-Light.ttf);
}
@font-face {
  font-family: "Averia-Regular";
  src: url(../src/Components/Fonts/AveriaSerifLibre-Regular.ttf);
}

html {
  scroll-behavior: smooth;
}

.code-blue{
  color: #01A1D1;
}

body{
  background-color: rgb(152, 152, 152,0.2)!important;
}

.whatsapp-link{
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  animation: bounce 2s infinite;
  z-index: 10000;
}

.whatsapp-link img{
  width: 50px;
  height: 50px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}