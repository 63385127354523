.accordion {
    overflow: hidden;
  }
  
  .accordion-header {
    cursor: pointer;
    user-select: none;
    background-color: #01A1D1;
  }
  
  .accordion-content::-webkit-scrollbar {
        width: 3px; /* Width of the scrollbar */
  }

  .accordion-content::-webkit-scrollbar-track {
    background: #ffffff; /* Track color */
    border-radius: 10px;
  }

  .accordion-content::-webkit-scrollbar-thumb {
    background:#01A1D1; /* Thumb color */
    border-radius: 10px;
  }
  
  .accordion-content::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
  }
  
  .accordion-text {
    padding: 5px 10px;
  }

 
  