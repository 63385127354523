.blog-bg{
    background-image: url('../../Images/dentalPic/blogbg.png');
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
}

.blog-head{
    text-align: center;
    font-family: 'Averia-Bold';
    background-color: rgb(255, 255, 255,0.7);
   
}

.blog-card{
    height: 880px;
    background-color: rgb(255, 255, 255,0.7);
    overflow: auto;
}

.blog-head h3{
    font-size: 50px;
    color: #63C1CB;
    font-family: 'Averia-Bold';
}

.blog-desc{
    overflow: hidden;
    font-family: 'Averia-Bold';
}

.blog-card-head{
    color: #01A1D1;
}

.blog-card::-webkit-scrollbar {
    width: 6px;
  }
  
  .blog-card::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .blog-card::-webkit-scrollbar-thumb {
    background-color: #01A1D1;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }

/* Small Mobile Styles */
@media (max-width: 480px) {
    .blog-bg {
        height: max-content; /* Reduced height for small screens */
    }

    .blog-head {
        padding: 20px;
    }

    .blog-head h3 {
        font-size: 24px;
        padding: 10px;
    }

    .blog-card {
        height: 500px; /* Reduced height for small screens */
        margin: 10px;
    }

    .blog-desc {
        font-size: 14px;
    }

    .blog-card-head {
        font-size: 16px;
    }
}

/* Mobile Styles */
@media (min-width: 481px) and (max-width: 767px) {
    .blog-bg {
         /* Use a mobile-friendly background image if available */
         height: max-content;/* Adjusted height for mobile screens */
    }

    .blog-head {
        padding: 30px;
    }

    .blog-head h3 {
        font-size: 32px;
        padding: 20px;
    }

    .blog-card {
        height: 350px; /* Adjusted height for mobile screens */
        margin: 15px;
    }

    .blog-desc {
        font-size: 16px;
    }

    .blog-card-head {
        font-size: 18px;
    }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1024px) {
    .blog-bg {
        /* Use a tablet-friendly background image if available */
        height: 600px; /* Adjusted height for tablet screens */
    }

    .blog-head {
        padding: 40px;
    }

    .blog-head h3 {
        font-size: 40px;
        padding: 30px;
    }

    .blog-card {
        height: 400px; /* Adjusted height for tablet screens */
        margin: 20px;
    }

    .blog-desc {
        font-size: 18px;
    }

    .blog-card-head {
        font-size: 20px;
    }
}

/* Laptop Styles */
@media (min-width: 1025px) {
    .blog-bg {
        /* Use the standard background image */
        height: max-content; /* Default height for larger screens */
    }

    .blog-head {
        padding: 50px;
    }

    .blog-head h3 {
        font-size: 50px;
        padding: 40px;
    }

    .blog-card {
        height: 580px; /* Default height for larger screens */
        margin: 25px;
    }

    .blog-desc {
        font-size: 20px;
    }

    .blog-card-head {
        font-size: 22px;
    }
}
