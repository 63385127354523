.care-head{
    text-align: center;
    font-family: 'Averia-Bold';
    color: #01A1D1;
    margin: 20px 0px;
}

.special-card{
    position: relative;
    margin: 10px;
}
.sp-img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 80% 20%;
}

.special-card-content{
    position: absolute;
    top: 0;
    height: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: 'Averia-Bold';
    color: #000000;
    visibility: hidden;
    background-color: rgb(255, 255, 255,0.9);
}

.special-card:hover .special-card-content{
    visibility: visible;
    cursor: pointer;
}