.align-main-img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}


.aligner-head{
    width: 100%;
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-child-1{
    position: absolute;
    top: 10%;
    left: 0;
    z-index: -2;
    width: 100%;
}

.align-head-name{
    font-size: 40px;
    color: white;
    text-align: center;
    font-family: 'Averia-Bold';
}

.align-child-2{
    position: absolute;
    top: 0;
    width: 100%;
    height: 300px;
    background-color: rgb(1, 161, 209,0.5);
    z-index: -1;
}

.align-about{
    text-align: center;
    position: relative;
}

.align-content-sec{
}

.align-adbout-head{
    font-family: 'Averia-Regular';
}

.align-about-content{
    font-family: 'Averia-Regular';
    font-size: 18px;
}

.align-about-img{
    width: 380px;
    height: 350px;
    object-fit: cover;
    object-position: 40% 20%;
    border-radius: 10px 10px 50% 50%;
}

.align-content-img{
    width: 550px;
    height: 550px;
    object-fit: cover;
    object-position: 40% 20%;
}

.align-about-tooth{
    position: absolute;
    width: 150px;
    height: 150px;
    right: 60px;
    top: 50%;
    transform: rotate(-25deg);
    opacity: 0.7;
}

.invisible-content-head{
    font-family: 'Averia-Bold';
    text-align: center;
    margin: 50px 0px;
}

.invisible-content{
    font-family: 'Averia-Regular';
}

.invisible-content p{
    padding: 10px 0px;
    font-size: 18px;
}

.invisible-content strong{
    color: rgb(1, 161, 209,0.9);
}

/* Small Mobile Styles */
@media (max-width: 480px) {
    .align-main-img {
        height: 200px; /* Reduced height for small screens */
        width: 100%;
        object-fit: contain;
    }

    .align-child-1 {
        top: 0;
        left: 0;
    }

    .align-head-name {
        font-size: 22px;
    }

    .align-child-2 {
        height: 200px; /* Reduced height for small screens */
    }

    .align-content-sec {
        /* Adjusted height for small screens */
    }

    .align-about-img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }

    .align-content-img {
        width: 100%;
        height: auto;
    }

    .align-about-tooth {
        width: 100px;
        height: 100px;
        right: 20px;
        top: 40%;
    }

    .invisible-content p {
        font-size: 16px;
    }
}

/* Mobile Styles */
@media (min-width: 481px) and (max-width: 767px) {
    .align-main-img {
        height: 250px; /* Adjusted height for mobile screens */
        width: 100%;
        object-fit: contain;
    }

    .align-child-1 {
        top: 0;
        left: 0;
    }

    .align-head-name {
        font-size: 24px;
    }

    .align-child-2 {
        height: 200px; /* Adjusted height for mobile screens */
    }

    .align-content-sec {
        /* Adjusted height for mobile screens */
    }

    .align-about-img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    .align-content-img {
        width: 100%;
        height: auto;
    }

    .align-about-tooth {
        width: 120px;
        height: 120px;
        right: 30px;
    }

    .invisible-content p {
        font-size: 17px;
    }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1024px) {
    .align-main-img {
        height: 250px; /* Adjusted height for tablet screens */
        width: 100%;
        object-fit: contain;
    }

    .align-child-1 {
        top: 0;
        left: 0;
    }

    .align-head-name {
        font-size: 32px;
    }

    .align-child-2 {
        height: 300px; /* Adjusted height for tablet screens */
    }

    .align-content-sec {
        /* Adjusted height for tablet screens */
    }

    .align-about-img {
        width: 100%;
        height: auto;
        border-radius: 20px;
    }

    .align-content-img {
        width: 100%;
        height: auto;
    }

    .align-about-tooth {
        width: 130px;
        height: 130px;
        right: 40px;
    }

    .invisible-content p {
        font-size: 18px;
    }
}

/* Laptop Styles */
@media (min-width: 1025px) {
    .align-main-img {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }

    .align-child-1 {
        top: 0;
        left: 0;
    }

    .align-head-name {
        font-size: 40px;
    }

    .align-child-2 {
        height: 300px;
    }

    .align-content-sec {
        
    }

    .align-about-img {
        width: 380px;
        height: 350px;
        border-radius: 10px 10px 50% 50%;
    }

    .align-content-img {
        width: 550px;
        height: 550px;
    }

    .align-about-tooth {
        width: 150px;
        height: 150px;
        right: 60px;
    }

    .invisible-content p {
        font-size: 18px;
    }
}
