#appointment{
    margin-top: 70px;
}
.appointment-form{
    width: 70%;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.appointment-form h3{
    text-align: center;
    font-family: 'Averia-Regular';
}

.appointment-right{
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}
.appointment-right h4{
    font-family: 'Averia-Regular';
    font-size: 18px;
    color: #01A1D1;
}

.appointment-right strong{
    color: #01A1D1;
}

.appointment-right h2{
    font-family: 'Averia-Bold';
    font-size: 26px;
}

.appointment-right p{
    font-family: 'Averia-light';
    font-size: 19px;
}

.social-media{
    display: flex;
}

.social-media a{
    text-decoration: none!important;
    color: black;
}

.social-media i{
    cursor: pointer;
}

.social-media i:hover{
    color: #01A1D1;
}

@media only screen and (max-width: 576px) {
    .appointment-form {
        width: 90%;
        padding: 15px;
    }

    .appointment-right {
        height: auto;
        align-items: center;
        text-align: center;
    }

    .appointment-right h2 {
        font-size: 22px;
    }

    .appointment-right p {
        font-size: 16px;
    }

    .social-media {
        justify-content: center;
    }
}

/* Tablet devices */
@media only screen and (min-width: 577px) and (max-width: 768px) {
    .appointment-form {
        width: 80%;
        padding: 18px;
    }

    .appointment-right {
        height: auto;
        align-items: center;
        text-align: center;
    }

    .appointment-right h2 {
        font-size: 24px;
    }

    .appointment-right p {
        font-size: 17px;
    }

    .social-media {
        justify-content: center;
    }
}

/* Laptops and small desktops */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .appointment-form {
        width: 75%;
        padding: 20px;
    }

    .appointment-right {
        height: 500px;
        align-items: flex-start;
        text-align: left;
    }

    .appointment-right h2 {
        font-size: 26px;
    }

    .appointment-right p {
        font-size: 18px;
    }

    .social-media {
        justify-content: flex-start;
    }
}

/* Large screens (TVs) */
@media only screen and (min-width: 1025px) {
    .appointment-form {
        width: 60%;
        padding: 25px;
    }

    .appointment-right {
        height: 700px;
        align-items: flex-start;
        text-align: left;
    }

    .appointment-right h2 {
        font-size: 28px;
    }

    .appointment-right p {
        font-size: 20px;
    }

    .social-media {
        justify-content: flex-start;
    }
}