.special-head {
    text-align: center;
    font-family: 'Averia-Bold';
    color: #01A1D1;
}

.country-main{
    width: 80%;
    margin: auto;
}

.country-main h1{
    text-align: center;
    font-family: 'Averia-Bold';
    color: #01A1D1;
}

.swiper-slide .country-image{
    border-radius: 30px;
    width: 320px;
    height: 250px;
    object-fit: cover;
    object-position: 80% 20%;
}

.swiper-slide{
    background: transparent;
}

.swiper-3d .swiper-slide-shadow-left{
    background-image: none!important;
}

.swiper-3d .swiper-slide-shadow-right{
    background-image: none!important;
}

.swiper-slide-uni{
    margin: 30px;
    width: 320px!important;
    height: 300px;
    flex-wrap: wrap;
    background: transparent;   
  }
.swiper-wrapper{
    background: transparent;
}

.country-content h2{
    padding: 10px 0px;
    font-size: 20px;
    text-align: center;
    font-family: 'Averia-Bold';
    color: #01A1D1;
}

.swiper-pagination-bullet-active{
    background: #01A1D1!important;
  }