.implant-main-img{
    position:relative;
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.implant-child-1{
    position: absolute;
    top: 25%;
    left: 45%;
    text-align: center;
}

.implant-head-name{
    font-size: 40px;
    color: white;
    text-align: center;
    font-family: 'Averia-Bold';
}

.implant-child-2{
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: rgb(1, 161, 209,0.5);
}

.implant-adbout-head{
    font-family: 'Averia-Regular';
}

.implant-about-content{
    font-family: 'Averia-Regular';
    font-size: 18px;
}

.implant-about{
    text-align: center;
    position: relative;
}

.implant-about-img{
    width: 380px;
    height: 350px;
    object-fit: cover;
    object-position: 40% 20%;
    border-radius: 10px 10px 50% 50%;
}
.implant-about-tooth{
    position: absolute;
    width: 150px;
    height: 150px;
    right: 60px;
    top: 50%;
    transform: rotate(-25deg);
    opacity: 0.7;
}
