.floating-menu {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .floating-menu .fl-item {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px 5px;
    width: 40px;
    height: 50px;
    color: #01A1D1;
    text-decoration: none;
    transition: width 0.3s ease, height 0.3s ease;
    overflow: hidden;
    cursor: pointer;
  }
  
  .fl-contact{
    background-color: #ffffff;
  }
  
  .fl-email{
    background-color: #ffffff;
  }
  
  .fl-link{
    background-color: #ffffff;
  }
  .fl-wa{
    background-color: #ffffff;
  }
  
  .fl-item:hover {
    width: 40px;
    height: 160px;
    background-color: #ffffff;
    overflow: visible;
  }
  
  .fl-item .icon {
    width: 24px;
    height: 24px;
    margin: 10px 20px;
    transition: 0.3s ease-in-out;
    
  }
  
  .fl-item img {
    width: 100%;
    height: 100%;
  }
  
  
  .fl-item .text {
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
    margin: 30px 20px;
    transform: rotate(-90deg);
    font-family: 'Averia-Bold';
  }
  
  .fl-item:hover .text {
    opacity: 1;
  }
  
  .fl-item:hover .icon{
    transition: 0.3s ease-in-out;
    transform: rotate(270deg);
  }

  
  
  .edge{
    border-radius: 0px 0px 8px 0px;
  }
  
  .edge-t{
    border-radius: 0px 8px 0px 0px;
  }

 

  .modal-content{
    border: 1px solid #01A1D1;
    box-shadow: 2px 2px 5px 01A1D1;
  }


  .modal-header{
    font-family: 'Averia-Bold';
    color: #01A1D1;
    border-bottom: 1px solid #01A1D1;
  }

  .fm-lb{
    font-family: 'Averia-Bold';
    color: #01A1D1;
  }

  .submit-btn{
    font-family: 'Averia-Bold';
    padding: 7px 30px;
  margin: 0 40px;
  border: 2px solid #01A1D1;
  border-radius: 10px;
  color: #01A1D1;
  text-decoration: none;
  font-size: 16px;
  background-color: #01A1D1;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  }


  @media screen and (max-width: 480px) {
    .floating-menu{
      display: none;
    }
  }
  
  
  