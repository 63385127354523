.rating-stars {
    display: flex;
    flex-direction: row;
  }
  
  .filled-star {
    color: #FFD700; /* Gold color for filled stars */
    font-size: 16px;
    margin-right: 5px;
  }
  
  .empty-star {
    color: #FFD700; /* Light grey color for empty stars */
    font-size: 16px;
    margin-right: 5px;
  }