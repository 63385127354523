.testimonial-card{
    height: 350px;
    width: 50%;
    margin: 20px 10px;
    background-color: rgb(255, 248, 248);
    padding: 10px 20px;
    margin: 20px 20px;
    box-shadow: 1px 0px 2px #01A1D1;
   
}

.testimonial-bg-design{
    height: max-content;
    margin: auto;
}

.child-bg img{
    position: absolute;
    top: -70px;
    left: 20%;
    width: 200px;
    height: 210px;
    opacity: 40%;
}

.testimonial-bg{
  
    height: fit-content;
    margin: 20px;
    width: 90%;
    margin: 20px auto;
    overflow: hidden;
}

.testimonial-head{
    text-align: center;
    padding: 10px 10px;
    font-family: 'Averia-Bold';
    color: #01A1D1;
    height: max-content;
}

.testimonial-head p{
    width: 80%;
    margin: auto;
    color: grey;
    font-family: 'Averia-Light';

}

.testimonial-card-img{
    width: 250px!important;
    height: 330px!important;    
    object-fit: cover ;
    object-position: 100% 30%;
}

.testimonial-name h3{
    padding-top:0px ;
    margin-bottom: 3px;
    font-size: 15px;
    font-family: 'Averia-Bold';
    color: #2c2c54;
}

.testimonial-name .desg{
    color: #818181;
}

.tl-country{
    font-size: 14px;
    color: #2c2c54;
    font-family: 'Averia-Semi';
}

.testimonial-content p{
    padding-left: 20px;
    font-family: 'Averia-Semi';
    color: #818181;

}

.testi-qoute .ryt{
    transform: rotate(-90deg);
}

.testi-qoute{
    width: 20px!important;
    height: 20px!important;
}

.card-new {
    width: 350px;
    height: 300px;
    transform-style: preserve-3d;
    perspective: 500px;
    border: none;
    background-color: inherit;
}

.card-new .face {
    color:white;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.06);
    transform-style: preserve-3d;
    transition: 0.5s;
    backface-visibility: hidden;
    border-top: 1px solid #01A1D1;
    border-left: 1px solid #01A1D1;
    /* border-right: 1px solid #999;
border-bottom: 1px solid #999; */
}

.card-new .face.front-face,
.card-new .face.back-face {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.card-new .face.front-face .profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 100% 20%;
}


.card-new .face.front-face .name {
    color: #000000;
    font-family: 'Averia-Bold';
}

.card-new .face.front-face .designation {
    font-size: 0.8rem;
    color: #818181;
    font-family: 'Averia-Bold';
}

.card-new:hover .face.front-face {
    /* transform: rotateY(180deg); */
}

.card-new .face.back-face {
    background: #01A1D1;
    transform: rotateY(180deg);
    padding: 20px 30px;
    text-align: center;
    user-select: none;
}

.card-new .face.back-face .fa-quote-left {
    top: 15px;
    left: 20px;
    font-size: 1.2rem;
}

.card-new .face.back-face .fa-quote-right {
    bottom: 15px;
    right: 15px;
    font-size: 1.2rem;
}

.card-new:hover .face.back-face {
    /* transform: rotateY(360deg); */
}

.testimonial-wrapper .swiper-wrapper{
    padding: 20px;

}

.testimonial-review{
    padding: 20px 0px;
    font-size: 15px;
    font-family: 'Averia-Light';
    color:black;
    overflow: auto;
}

.testimonial-review::-webkit-scrollbar {
    width: 6px;
  }
  
  .testimonial-review::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .testimonial-review::-webkit-scrollbar-thumb {
    background-color: #01A1D1;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }

  .testimonial-wrapper .swiper-pagination{
    display: none;
  }

/* Card hover effect */

@media screen and (max-width:1400px){
  .testimonial-wrapper{
    width: 100%;
  }

  .card-new{
    width: 280px;
  }
}

/* Media queries for laptop screens */
@media screen and (max-width: 1024px) {
  .testimonial-card {
      width: 45%;
      height: auto;
      margin: 15px 10px;
  }

  .child-bg img {
      width: 150px;
      height: 160px;
  }

  .testimonial-bg {
      height: 600px;
  }

  .testimonial-head p {
      width: 70%;
      font-size: 22px;
  }

  .testimonial-card-img {
      width: 70px;
      height: 70px;
  }

  .testimonial-name h3 {
      font-size: 14px;
  }

  .tl-country {
      font-size: 13px;
  }

  .testimonial-content p {
      font-size: 14px;
  }
}

/* Media queries for tablet screens */
@media screen and (max-width: 768px) {
  .testimonial-card {
      width: 90%;
      height: auto;
      margin: 10px 5px;
  }

  .child-bg img {
      width: 120px;
      height: 130px;
  }

  .testimonial-bg {
      height: 750px;
  }

  .testimonial-head p {
      width: 80%;
      height: max-content;
      font-size: 20px;
  }

  .testimonial-card-img {
      width: 60px;
      height: 60px;
  }

  .testimonial-name h3 {
      font-size: 13px;
  }

  .tl-country {
      font-size: 12px;
  }

  .testimonial-content p {
      font-size: 13px;
  }
}

/* Media queries for mobile screens */
@media screen and (max-width: 480px) {
    .testimonial-review{
        font-size: 14px;
        font-family: "Metropolis-Light", sans-serif;
    }
  .testimonial-card {
      width: 90%;
      height: auto;
      margin: 10px 0px;
  }

  .child-bg img {
      width: 80px;
      height: 90px;
      top: -30px;
      left: 5%;
  }

  .testimonial-bg {
      height: auto;
  }

  .testimonial-head {
      padding: 10px 10px;
  }

  .testimonial-head p {
      width: 90%;
      font-size: 18px;
  }

  .testimonial-card-img {
      width: 100px !important;
      height: 100px !important;
      object-fit: cover;
      object-position: 100% 30%;
  }

  .testimonial-name h3 {
      font-size: 12px;
  }

  .tl-country {
      font-size: 11px;
  }

  .testimonial-wrapper {
    align-content: center;
  }

  .testimonial-wrapper .swiper-wrapper {
      padding: 10px 0px;
  }

  .testimonial-content p {
      font-size: 12px;
  }

  .card-new {
      width: 350px;
      height: 350px;
  }

  .card-new .face {
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }

  .card-new .face.front-face .profile {
      width: 50px;
      height: 50px;
  }

  .card-new .face.front-face .name {
      font-size: 0.9rem;
  }

  .card-new .face.front-face .designation {
      font-size: 0.7rem;
  }

  .card-new .face.back-face .fa-quote-left,
  .card-new .face.back-face .fa-quote-right {
      font-size: 1rem;
  }
}